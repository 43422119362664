<template>
  <b-row class="pt-3 pb-3 m-0 header">
    <!-- RouteName RecruiterProfile Buttons -->
    <b-col
      v-if="$route.name === 'RecruiterProfile'"
    >
      <b-col class="d-flex justify-content-end">
        <b-btn
          variant="ghost"
          :to="`/recruiter/${recruiter.id}/edit`"
        >
          <i class="ri-edit-2-line pr-2" />
          <span>
            Edit Profile
          </span>
        </b-btn>
      </b-col>
    </b-col>
    <!-- RouteName ProposalView Buttons -->
    <b-col v-if="$route.name === 'ProposalView' && (selectedMatch || {}).project">
      <b-col class="h7">
        {{ selectedMatch.project.title }}
      </b-col>
    </b-col>
    <!-- RouteName MatchSingle Buttons -->
    <b-col
      v-if="$route.name === 'MatchSingle' && (selectedMatch || {}).project"
      class="h7 d-flex"
    >
      <b-col>
        {{ selectedMatch.project.title }}
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-btn
          v-if="[MATCH_STATUSES.proposal.value].includes(selectedMatch.status)"
          class="mr-3"
          variant="secondary"
          :to="`/fee-proposal/${$route.params.id}/edit`"
        >
          <i class="ri-money-euro-circle-line pr-2" />
          <span>
            Send fee proposal
          </span>
        </b-btn>
        <b-btn
          v-if="[
            MATCH_STATUSES.new.value,
            MATCH_STATUSES.recruiterIsInterested.value,
            MATCH_STATUSES.proposal.value,
          ].includes(selectedMatch.status)"
          v-b-modal.decline-modal
          class="mr-3"
          variant="primary"
        >
          <i class="ri-thumb-down-line pr-2" />
          <span>
            Not interested
          </span>
        </b-btn>
        <b-btn
          v-if="selectedMatch.status === MATCH_STATUSES.new.value"
          v-b-modal.accept-modal
          variant="secondary"
        >
          <i class="ri-vidicon-line pr-2" />
          <span>
            Interested
          </span>
        </b-btn>
      </b-col>
    </b-col>
    <!-- Fee Proposal BTNs & Logic
    <b-col
      v-if="$route.name === 'ProposalForm'"
      class="h7 d-flex"
    >
      <b-col>
        {{ match.project.title }}
      </b-col>
    </b-col> -->
    <!-- Modals -->
    <b-modal
      id="accept-modal"
      ok-only
      ok-title="Send"
      no-stacking
      centered
      @ok="modalConfirmation"
    >
      <label class="h7 mb-3">
        Add a Cover Letter
      </label>
      <label class="mb-3 pl-3 pr-3">
        Let them know why you’re the right recruiter for this project. Highlight your skills, strengths and experience.
      </label>
      <b-form-textarea
        v-model="coverLetter"
        class="modal-feedback"
        placeholder="Add your message here..."
        rows="4"
      />
    </b-modal>
    <b-modal
      id="decline-modal"
      ok-only
      ok-title="Send"
      no-stacking
      centered
      @ok="modalDeclineConfirmation"
    >
      <label class="h7 mb-3">
        Not the best match?
      </label>
      <label class="mb-3 pl-3 pr-3">
        Your feedback helps improve your future matches.
      </label>
      <b-form-textarea
        v-model="feedback"
        class="modal-feedback"
        placeholder="Add your feedback here..."
        rows="4"
      />
    </b-modal>
  </b-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import axios from '@/helpers/cleanAxios'
import { MATCH_STATUSES } from '@/helpers/statuses'

export default {
  name: 'RecruiterHeader',

  data () {
    return {
      feedback: '',
      coverLetter: '',
      MATCH_STATUSES,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters('auth', ['recruiter']),
    ...mapGetters('recruiter', ['selectedMatch']),
  },
  methods: {
    modalConfirmation () {
      axios.patch(`/v2/projects/${this.$route.params.id}/interested`, {
        coverLetter: this.coverLetter,
      })
        .then(() => {
          this.coverLetter = ''
          this.$bvModal.msgBoxOk('Thanks for your message!', {
            title: ' ',
            size: 'md',
            buttonSize: 'lg',
            headerClass: 'p-4',
            centered: true,
          })
            .then(() => {
              location.reload()
            })
        })
        .catch(() => {
          this.$bvModal.msgBoxOk('Error saving response!', {
            bodyBgVariant: 'danger',
          })
        })
    },
    modalDeclineConfirmation () {
      axios.patch(`/v2/projects/${this.$route.params.id}/not-interested`, {
        feedback: this.feedback,
      })
        .then(() => {
          this.feedback = ''
          this.$bvModal.msgBoxOk('Thanks for your message!', {
            title: ' ',
            size: 'md',
            buttonSize: 'lg',
            headerClass: 'p-4',
            centered: true,
          })
            .then(() => {
              location.reload()
            })
        })
        .catch(() => {
          this.$bvModal.msgBoxOk('Error saving response!', {
            bodyBgVariant: 'danger',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  background-color: #f8eeec;
  min-height: 73px;
}
</style>
