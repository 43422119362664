import Axios from 'axios'
import Vue from '../main'

function readError (error) {
  let errorMessage = error.message
  if (error.response && error.response.data) {
    errorMessage = error.response.data
  }
  if (error.response && error.response.message) {
    errorMessage = error.response.message
  }
  if (error.response?.data?.errors?.[0]?.message) {
    errorMessage = error.response.data.errors?.[0].message
  }
  return errorMessage
}

let activeErrors = false

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
})

axios.interceptors.response.use(null, function (error) {
  if (error.response?.status === 401) {
    console.log('Token expired, need to relog')
    window.localStorage.clear()
    window.location.href = '/'
    return
  }
  if (!activeErrors) {
    activeErrors = true
    Vue.$bvToast.toast(readError(error), {
      title: 'Error',
      variant: 'danger',
      autoHideDelay: 3000,
      solid: true,
    })
    setTimeout(() => {
      activeErrors = false
    }, 3000)
  }
  return Promise.reject(error)
})

export default axios

export {
  readError,
}
