import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
})

axios.interceptors.response.use(null, function (error) {
  if (error.response?.status === 401) {
    console.log('Token expired, need to relog')
    window.localStorage.clear()
    window.location.href = '/'
    return
  }
  return Promise.reject(error)
})

export default axios
