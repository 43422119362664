import Axios from '@/helpers/axios'
import { MATCH_STATUSES, statusByValue } from '@/helpers/statuses'

const STATUS_SELECTED = [
  MATCH_STATUSES.clientIsInterested.value,
  MATCH_STATUSES.introMeeting.value,
  MATCH_STATUSES.proposal.value,
  MATCH_STATUSES.contractSigning.value,
  MATCH_STATUSES.notHired.value,
]

const STATUS_HIRED = [
  MATCH_STATUSES.active.value,
  MATCH_STATUSES.contractCompleted.value,
  MATCH_STATUSES.contractCanceled.value,
  MATCH_STATUSES.feedback.value,
  MATCH_STATUSES.paused.value,
]

const STATUS_NEW = [
  MATCH_STATUSES.recruiterIsInterested.value,
]

export default {
  namespaced: true,

  state: () => ({
    matches: [],
  }),

  mutations: {
    SET_MATCHES (state, matches) {
      // Change status to number
      matches = matches.map(match => {
        match.status = statusByValue(MATCH_STATUSES, match.status)
        return match
      })

      // Sort by status
      matches = matches.sort((a, b) => (a.status.order > b.status.order) ? 1 : -1)

      // Change status back to string
      matches = matches.map(match => {
        match.status = match.status.value
        return match
      })

      state.matches = matches
    },
  },
  actions: {
    getMatches ({ commit, rootState }) {
      return Axios.get(`/v2/businesses/${rootState.auth.selectedBusiness.id}/matches`)
        .then(({ data }) => {
          commit('SET_MATCHES', data)
          return data
        })
    },
  },
  getters: {
    all: (state) => (projectFilter) => {
      let returnMatches = state.matches
      if (projectFilter) {
        returnMatches = returnMatches.filter(m => m.project.id === projectFilter)
      }
      return returnMatches
    },
    selected: (state) => (projectFilter) => {
      let returnMatches = state.matches.filter(p => STATUS_SELECTED.includes(p.status))
      if (projectFilter) {
        returnMatches = returnMatches.filter(m => m.project.id === projectFilter)
      }
      return returnMatches
    },
    hired: (state) => (projectFilter) => {
      let returnMatches = state.matches.filter(p => STATUS_HIRED.includes(p.status))
      if (projectFilter) {
        returnMatches = returnMatches.filter(m => m.project.id === projectFilter)
      }
      return returnMatches
    },
    new: (state) => (projectFilter) => {
      let returnMatches = state.matches.filter(p => STATUS_NEW.includes(p.status))
      if (projectFilter) {
        returnMatches = returnMatches.filter(m => m.project.id === projectFilter)
      }
      return returnMatches
    },
  },
}
