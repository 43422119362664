export default [
  // Business routes
  {
    path: '/business/:id',
    name: 'BusinessProfile',
    component: () => import('@/modules/business/views/Profile.vue'),
  },
  {
    path: '/business/:id/matches',
    name: 'BusinessMatches',
    component: () => import('@/modules/business/views/Matches.vue'),
  },
  {
    path: '/business/:id/edit',
    name: 'BusinessEdit',
    component: () => import('@/modules/business/views/BusinessEdit.vue'),
  },
  {
    path: '/business-match/:id/',
    name: 'BusinessMatch',
    component: () => import('@/modules/business/views/MatchSingle.vue'),
  },

  // Project routes
  {
    path: '/business/:id/projects',
    name: 'BusinessProjects',
    component: () => import('@/modules/business/views/Projects.vue'),
  },
  {
    path: '/project/:id/',
    name: 'ProjectSingle',
    component: () => import('@/modules/business/views/Project.vue'),
  },
  {
    path: '/published/project',
    name: 'projectPublished',
    component: () => import('@/views/project/PublishedView.vue'),
  },
  {
    path: '/project/:id/edit',
    name: 'ClientProjectEdit',
    component: () => import('@/views/project/ProjectForm.vue'),
  },
  // Proposal
  {
    path: '/fee-proposal/:id/',
    name: 'ProposalView',
    component: () => import('@/components/proposal/ProposalView.vue'),
  },
]
