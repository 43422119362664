export default [
  {
    path: '/recruiter/:id/',
    name: 'RecruiterProfile',
    component: () => import('@/modules/recruiter/views/RecruiterProfile.vue'),
  },
  {
    path: '/recruiter/:id/matches',
    name: 'RecruiterMatches',
    component: () => import('@/modules/recruiter/views/Matches.vue'),
  },
  {
    path: '/recruiter/:id/edit',
    name: 'RecruiterEdit',
    component: () => import('@/modules/recruiter/views/RecruiterEdit.vue'),
  },
  // Signup routes
  {
    path: '/signup/recruiter',
    name: 'SignupRecruiterInitialStep',
    component: () => import('@/modules/recruiter/views/InitialStep.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/signup/recruiter/goals',
    name: 'SignupRecruiterGoals',
    component: () => import('@/modules/recruiter/views/Goals.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/signup/recruiter/details',
    name: 'SignupRecruiterDetails',
    component: () => import('@/modules/recruiter/views/SignupForm.vue'),
    meta: {
      public: true,
    },
  },
  // Recruiter match route
  {
    path: '/recruiter/match/:id/',
    name: 'MatchSingle',
    component: () => import('@/modules/recruiter/views/MatchSingle.vue'),
  },
  // Proposal
  {
    path: '/fee-proposal/:id/edit',
    name: 'ProposalForm',
    component: () => import('@/components/proposal/FeeProposal.vue'),
  },
  {
    path: '/fee-proposal/:id/',
    name: 'ProposalView',
    component: () => import('@/components/proposal/ProposalView.vue'),
  },
]
