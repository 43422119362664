import axios from '@/helpers/axios'
import router from '@/router'
import Vue from '@/main'

export default {
  namespaced: true,

  state: () => ({
    proposalForm: {
      pricing_model: null,
      currency: null,
      hourly_rate: null,
      fixed_fee: null,
      contingency_fee: null,
      annual_salary: null,
      payments_frequency: null,
      twoInstalmentsPercentage1: null,
      twoInstalmentsPercentage2: null,
      threeInstalmentsPercentage1: null,
      threeInstalmentsPercentage2: null,
      threeInstalmentsPercentage3: null,
      oneInstalment: null,
      twoInstalment1: null,
      twoInstalment2: null,
      threeInstalment1: null,
      threeInstalment2: null,
      threeInstalment3: null,
      message: null,
      attachments: [],
      default_guarantee: null,
      custom_guarantee: null,
    },
  }),
  actions: {
    submitForm ({ state, commit }, matchId) {
      const sendData = state.proposalForm
      sendData.instalments = []
      if (state.proposalForm.payments_frequency === '1 instalment') {
        sendData.instalments.push({
          // value === percentage
          value: 100,
          payment_time: state.proposalForm.oneInstalment,
        })
      } else if (state.proposalForm.payments_frequency === '2 instalments') {
        sendData.instalments.push({
          value: state.proposalForm.twoInstalmentsPercentage1,
          payment_time: state.proposalForm.twoInstalment1,
        })
        sendData.instalments.push({
          value: state.proposalForm.twoInstalmentsPercentage2,
          payment_time: state.proposalForm.twoInstalment2,
        })
      } else if (state.proposalForm.payments_frequency === '3 instalments') {
        sendData.instalments.push({
          value: state.proposalForm.threeInstalmentsPercentage1,
          payment_time: state.proposalForm.threeInstalment1,
        })
        sendData.instalments.push({
          value: state.proposalForm.threeInstalmentsPercentage2,
          payment_time: state.proposalForm.threeInstalment2,
        })
        sendData.instalments.push({
          value: state.proposalForm.threeInstalmentsPercentage3,
          payment_time: state.proposalForm.threeInstalment3,
        })
      }
      axios.post(`/v2/matches/${matchId}/proposals`, sendData)
        .then(() => {
          Object.keys(state.proposalForm).forEach((key) => {
            commit('UPDATE_STORE', {
              nestedObject: 'proposalForm',
              data: {
                field: key,
                value: null,
              },
            })
          })
        })
        .then(() => {
          Vue.$bvModal.msgBoxOk('Your fee proposal has been sent!', {
            title: ' ',
            size: 'md',
            buttonSize: 'lg',
            headerClass: 'p-4 text-center',
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
          })
        })
        .then(() => {
          router.push('/messages')
        })
    },
  },
  mutations: {
    UPDATE_STORE (state, payload) {
      if (payload.nestedObject) {
        state[payload.nestedObject][payload.data.field] = payload.data.value
      } else {
        state[payload.data.field] = payload.data.value
      }
    },
  },
}
