import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Store from '../store'
import adminRoutes from '../modules/admin/routes'
import recruiterRoutes from '../modules/recruiter/routes'
import authRoutes from '../modules/auth/routes'
import agencyRoutes from '../modules/agency/routes'
import businessRoutes from '../modules/business/routes'

Vue.use(VueRouter)

const routes = [
  ...recruiterRoutes,
  ...adminRoutes,
  ...authRoutes,
  ...agencyRoutes,
  ...businessRoutes,

  // Temp dev routes
  {
    path: '/dev-form',
    name: 'DevForm',
    component: () => import('@/components/form/DevForm.vue'),
    meta: {
      public: true,
    },
  },

  // Public routes
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/fee-calculator',
    name: 'FeeCalculator',
    component: () => import('@/components/FeeCalculatorPage.vue'),
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('@/components/chat/Messages.vue'),
  },

  // Catch all 404
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // Adds openCalculator checker to open feeCalc componenet automatically,
  // when recruiters or Agencies got to app.relancer.com/#calculator url
  if (!Store.state.auth.user && window.location.hash === '#calculator') {
    window.sessionStorage.openCalculator = true
  }
  // Save route to sessionStorage
  // Only if user is not already logged in,
  // Redirection doesn't exist already
  // and it's not one of the public paths
  if (
    !Store.state.auth.user &&
    !window.sessionStorage.redirectTo &&
    !(to.meta.public || to.name === 'Home')
  ) {
    window.sessionStorage.redirectTo = to.fullPath
  }

  // Check if user is signed in
  if (Store.state.auth.user) {
    // Redirect public routes back to "home"
    if (to.meta.public) {
      return next('/')
    }
    if (to.path === '/') {
      if (Store.state.auth.selectedBusiness) {
        return next(`/business/${Store.state.auth.selectedBusiness.id}/projects`)
      } else {
        return next(`/recruiter/${Store.state.auth.selectedRecruiter.id}/projects`)
      }
    }
    return next()
  }
  // Otherwise allow only login
  if (to.meta.public) {
    return next()
  } else {
    return next('/login')
  }
})

export default router
