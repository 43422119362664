export default {
  namespaced: true,

  state: () => ({
    selectedMatch: null,
    selectedProject: null,
  }),

  mutations: {
    SET_MATCH (state, match) {
      state.selectedMatch = match
    },
    SET_PROJECT (state, project) {
      state.selectedProject = project
    },
  },

  getters: {
    selectedMatch: (state) => state.selectedMatch,
    selectedProject: (state) => state.selectedProject,
  },
}
