export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/modules/auth/views/Login.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/create-password',
    name: 'CreatePassword',
    component: () => import('@/modules/auth/views/CreatePassword.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/first-login',
    name: 'FirstLogin',
    component: () => import('@/modules/auth/views/FirstLogin.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/modules/auth/views/ForgotPassword.vue'),
    meta: {
      public: true,
    },
  },
]
