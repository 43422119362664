export default [
  {
    path: '/agency/:id',
    name: 'AgencyProfile',
    component: () => import('@/modules/agency/views/AgencyProfile.vue'),
  },
  {
    path: '/agency/:id/matches',
    name: 'AgencyMatches',
    component: () => import('@/modules/agency/views/Matches.vue'),
  },
  {
    path: '/agency/:id/edit',
    name: 'AgencyEdit',
    component: () => import('@/modules/agency/views/AgencyEdit.vue'),
  },

  // Signup routes
  {
    path: '/signup/agency',
    name: 'SignupAgencyInitialStep',
    component: () => import('@/modules/agency/views/InitialStep.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/signup/agency/goals',
    name: 'SignupAgencyGoals',
    component: () => import('@/modules/agency/views/Goals.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/signup/agency/details',
    name: 'SignupAgencyDetails',
    component: () => import('@/modules/agency/views/SignupForm.vue'),
    meta: {
      public: true,
    },
  },

  // Agency match route
  {
    path: '/agency/match/:id/',
    name: 'MatchSingle',
    component: () => import('@/modules/agency/views/MatchSingle.vue'),
  },
  // Proposal
  {
    path: '/fee-proposal/:id/edit',
    name: 'ProposalForm',
    component: () => import('@/components/proposal/FeeProposal.vue'),
  },
  {
    path: '/fee-proposal/:id/',
    name: 'ProposalView',
    component: () => import('@/components/proposal/ProposalView.vue'),
  },
]
