import Axios from '@/helpers/axios'
import { PROJECT_STATUSES, statusByValue } from '@/helpers/statuses'

export default {
  namespaced: true,

  state: () => ({
    projects: [],
    projectNotifications: 0,
  }),

  mutations: {
    SET_PROJECTS (state, projects) {
      projects = projects.map(project => {
        project.status = statusByValue(PROJECT_STATUSES, project.status)
        return project
      })

      projects = projects.sort((a, b) => (a.status.order > b.status.order) ? 1 : -1)

      projects = projects.map(project => {
        project.status = project.status.value
        return project
      })

      state.projects = projects
    },
    SET_PROJECTS_NOTIF_NR (state, nr) {
      state.projectNotifications = nr
    },
  },
  actions: {
    getProjects ({ commit, rootState }) {
      return Axios.get(`/v2/businesses/${rootState.auth.selectedBusiness.id}/projects`)
        .then(({ data }) => {
          commit('SET_PROJECTS', data)
          return data
        })
    },
  },
  getters: {
    drafts: (state) => {
      return state.projects.filter(p => p.status === PROJECT_STATUSES.draft.value)
    },
    open: (state) => {
      return state.projects.filter(p => p.status === PROJECT_STATUSES.open.value)
    },
    active: (state) => {
      return state.projects.filter(p => p.status === PROJECT_STATUSES.active.value)
    },
    closed: (state) => {
      return state.projects.filter(p => p.status === PROJECT_STATUSES.closed.value)
    },
  },
}
