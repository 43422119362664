import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGtm from '@gtm-support/vue2-gtm'
import store from './store'

import { BootstrapVue } from 'bootstrap-vue'
import '@/assets/styles/main.scss'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  Vue,
  dsn: process.env.NODE_ENV === 'production'
    ? 'https://3db5bc07db374fdf96180188d474e36b@sentry.ap3k.pro/3'
    : null,
  integrations: [new Integrations.BrowserTracing()],
  logErrors: true,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

Sentry.configureScope(scope => {
  scope.setExtra('localStorage', window.localStorage)
})

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

// Vue gtm & google analytics
Vue.use(VueGtm, {
  id: 'GTM-54GP8NJ',
  enabled: process.env.NODE_ENV === 'production',
})

Vue.config.productionTip = false

const CURRENT_VERSION = '3.4'

if (!window.localStorage.version || window.localStorage.version !== CURRENT_VERSION) {
  window.localStorage.clear()
  window.localStorage.version = CURRENT_VERSION
  window.location.reload()
}

export default new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
