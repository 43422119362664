<template>
  <b-sidebar
    v-if="user"
    no-header
    visible
    width="256px"
    no-close-on-route-change
  >
    <template #footer>
      <b-row
        v-if="!business"
      >
        <b-col class="text-center">
          <div class="sidebar-nav-links d-inline pointer">
            <span>
              <b-btn
                v-b-modal.FeeCalculator
                class="h9"
                variant="text"
              >
                Fee Calculator
              </b-btn>
              <FeeCalculator />
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="!business"
        class="mb-3"
      >
        <b-col class="text-center">
          <div class="sidebar-nav-links d-inline pointer">
            <span
              class="h9"
            >
              <a
                href="https://drive.google.com/file/d/1uSBZkEfM7uFkzwP8dJZd6rxnxCfN28GC/view?usp=sharing"
                target="_plank"
              >
                Getting Started Guide
              </a>
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="text-center">
          <i class="ri-logout-box-r-line ri-xl" />
          <div class="sidebar-nav-links d-inline pointer">
            <span
              class="sidebar-logout h8 pl-1"
              @click="logOut"
            >
              Log out
            </span>
          </div>
        </b-col>
      </b-row>
    </template>
    <b-col no-gutters>
      <!-- Logo  -->
      <b-row class="mt-2">
        <router-link
          v-if="recruiter"
          :to="`/recruiter/${recruiter.id}`"
          class="col sidebar-nav-links"
        >
          <LogoWithTextDark />
        </router-link>
        <router-link
          v-if="business"
          :to="`/business/${business.id}`"
          class="col sidebar-nav-links"
        >
          <LogoWithTextDark />
        </router-link>
        <router-link
          v-if="agency"
          :to="`/agency/${agency.id}`"
          class="col sidebar-nav-links"
        >
          <LogoWithTextDark />
        </router-link>
      </b-row>

      <!-- Profile image and stuff -->
      <b-row class="mt-5 d-flex pl-2">
        <!-- Image -->
        <b-col cols="2">
          <b-img
            v-if="!agency"
            :src="$store.state.auth.user.profile_picture"
            alt="user-pic"
            class="user-pic"
            width="45"
            height="45"
          />
          <b-img
            v-if="agency"
            :src="'/images/client-profile-img.svg'"
            alt="user-pic"
            class="user-pic"
            width="45"
            height="45"
          />
        </b-col>

        <!-- Business data -->
        <b-col v-if="business">
          <router-link
            :to="`/business/${business.id}`"
            class="sidebar-nav-links"
          >
            <b-row>
              <b-col>
                <small class="sidebar-user h8 pl-2">
                  {{ user.full_name }}
                </small>
              </b-col>
            </b-row>
          </router-link>
          <router-link
            :to="`/business/${business.id}`"
            class="sidebar-nav-links"
          >
            <b-row>
              <b-col class="ml-2">
                <small class="sidebar_user-company h8">
                  {{ business.name }}
                </small>
              </b-col>
            </b-row>
          </router-link>
        </b-col>

        <!-- Recruiter data -->
        <b-col v-if="recruiter">
          <router-link
            :to="`/recruiter/${recruiter.id}`"
            class="sidebar-nav-links"
          >
            <b-row>
              <b-col>
                <small class="sidebar-user h8 pl-2">
                  {{ user.full_name }}
                </small>
              </b-col>
            </b-row>
          </router-link>
          <router-link
            :to="`/recruiter/${recruiter.id}`"
            class="sidebar-nav-links"
          >
            <b-row>
              <b-col>
                <small class="sidebar_user-company h8 pl-2">My Profile</small>
              </b-col>
            </b-row>
          </router-link>
        </b-col>

        <!-- Agency data -->
        <b-col v-if="agency">
          <router-link
            :to="`/agency/${agency.id}`"
            class="sidebar-nav-links"
          >
            <b-row>
              <b-col>
                <small class="sidebar-user h8 pl-2">
                  {{ user.full_name }}
                </small>
              </b-col>
            </b-row>
          </router-link>
          <router-link
            :to="`/agency/${agency.id}`"
            class="sidebar-nav-links"
          >
            <b-row>
              <b-col>
                <small class="sidebar_user-company h8 pl-2">My Profile</small>
              </b-col>
            </b-row>
          </router-link>
        </b-col>
      </b-row>

      <!-- Business profile -->
      <router-link
        v-if="business"
        :to="`/business/${business.id}`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols mt-4 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-building-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Company Profile</span>
          </b-col>
        </b-row>
      </router-link>

      <!-- Busines projects -->
      <router-link
        v-if="business"
        :to="`/business/${business.id}/projects`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols mt-1 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-suitcase-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Projects</span>
          </b-col>
        </b-row>
      </router-link>

      <!-- Business matches -->
      <router-link
        v-if="business"
        :to="`/business/${business.id}/matches`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols mt-1 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-group-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Recruiter Matches</span>
          </b-col>
        </b-row>
      </router-link>
      <!-- Business messages -->
      <router-link
        v-if="business"
        to="/messages"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols mt-1 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-question-answer-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">
              Messages
              <b-badge
                v-if="notifications.messages.length"
                v-b-tooltip.hover
                title="New matches"
                class="ml-1 badge"
              >
                {{ notifications.messages.length }}
              </b-badge>
            </span>
          </b-col>
        </b-row>
      </router-link>

      <!-- Business Project Proposals -->
      <!-- <router-link
        v-if="business"
        :to="`/business/${business.id}/project-proposals`"
        class="sidebar-nav-links"
      >
        <b-row
          :class="{active: $route.name === 'ProjectProposals'}"
          class="option-cols mt-1 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-file-list-3-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Project Proposals</span>
          </b-col>
        </b-row>
      </router-link> -->

      <!-- Business notifications -->
      <!-- <b-row
        v-if="business"
        class="option-cols mt-4 pt-2 pb-2"
      >
        <b-col>
          <i class="ri-notification-line ri-xl" />
          <router-link
            :to="`/business/${business.id}/matches`"
            class="sidebar-nav-links"
          >
            <span class="sidebar-menu-headings h8 pl-2">
              Notifications
              <b-badge
                v-b-tooltip.hover
                title="New matches"
                class="ml-1 badge"
                :to="`/business/${business.id}/matches`"
              >
                {{ $store.state.projects.projectNotifications }}
              </b-badge>
            </span>
          </router-link>
        </b-col>
      </b-row> -->

      <!-- Recruiter matches -->
      <router-link
        v-if="recruiter"
        :to="`/recruiter/${recruiter.id}/matches`"
        class="sidebar-nav-links"
      >
        <b-row
          :class="{active: $route.name === 'RecruiterMatches'}"
          class="option-cols mt-4 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-suitcase-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Matches</span>
          </b-col>
        </b-row>
      </router-link>

      <!-- Recruiter Project Proposals -->
      <!-- <router-link
        v-if="recruiter"
        :to="`/recruiter/${recruiter.id}/project-proposals`"
        class="sidebar-nav-links"
      >
        <b-row
          :class="{active: $route.name === 'ProjectProposals'}"
          class="option-cols mt-1 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-file-list-3-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Project Proposals</span>
          </b-col>
        </b-row>
      </router-link> -->

      <!-- Agency matches -->
      <router-link
        v-if="agency"
        :to="`/agency/${agency.id}/matches`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols mt-4 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-suitcase-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Matches</span>
          </b-col>
        </b-row>
      </router-link>

      <!-- Recruiter messages -->
      <router-link
        v-if="recruiter"
        to="/messages"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols mt-1 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-question-answer-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">
              Messages
              <b-badge
                v-if="notifications.messages.length"
                v-b-tooltip.hover
                title="New messages"
                class="ml-1 badge"
              >
                {{ notifications.messages.length }}
              </b-badge>
            </span>
          </b-col>
        </b-row>
      </router-link>

      <!-- Agency messages -->
      <router-link
        v-if="agency"
        to="/messages"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols mt-1 pt-2 pb-2"
        >
          <b-col>
            <i class="ri-question-answer-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">
              Messages
              <b-badge
                v-if="notifications.messages.length"
                v-b-tooltip.hover
                title="New messages"
                class="ml-1 badge"
              >
                {{ notifications.messages.length }}
              </b-badge>
            </span>
          </b-col>
        </b-row>
      </router-link>

      <!-- Admin stuff -->
      <b-row
        v-if="user.role === 'admin'"
        class="admin-cols"
      >
        <b-col>
          ADMIN links
        </b-col>
      </b-row>
      <router-link
        v-if="user.role === 'admin'"
        :to="`/admin/user/create`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols"
        >
          <b-col>
            <i class="ri-psychotherapy-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Create User</span>
          </b-col>
        </b-row>
      </router-link>
      <router-link
        v-if="user.role === 'admin'"
        :to="`/admin/business/create`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols"
        >
          <b-col>
            <i class="ri-amazon-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Create Client</span>
          </b-col>
        </b-row>
      </router-link>
      <router-link
        v-if="user.role === 'admin'"
        :to="`/admin/project/create`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols"
        >
          <b-col>
            <i class="ri-surgical-mask-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">Create Project</span>
          </b-col>
        </b-row>
      </router-link>
      <router-link
        v-if="user.role === 'admin'"
        :to="`/admin/business`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols"
        >
          <b-col>
            <i class="ri-list-settings-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">All Clients</span>
          </b-col>
        </b-row>
      </router-link>
      <router-link
        :to="`/admin/recruiter`"
        class="sidebar-nav-links"
      >
        <b-row
          v-if="user.role === 'admin'"
          class="option-cols"
        >
          <b-col>
            <i class="ri-account-box-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">All Recruiters</span>
          </b-col>
        </b-row>
      </router-link>
      <router-link
        :to="`/admin/agency`"
        class="sidebar-nav-links"
      >
        <b-row
          v-if="user.role === 'admin'"
          class="option-cols"
        >
          <b-col>
            <i class="ri-hand-coin-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">All agencies</span>
          </b-col>
        </b-row>
      </router-link>
      <router-link
        v-if="user.role === 'admin'"
        :to="`/admin/user`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols"
        >
          <b-col>
            <i class="ri-open-arm-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">All Users</span>
          </b-col>
        </b-row>
      </router-link>
      <router-link
        v-if="user.role === 'admin'"
        :to="`/admin/projects`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols"
        >
          <b-col>
            <i class="ri-aliens-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">All Projects</span>
          </b-col>
        </b-row>
      </router-link>
      <router-link
        v-if="user.role === 'admin'"
        :to="`/admin/matches`"
        class="sidebar-nav-links"
      >
        <b-row
          class="option-cols"
        >
          <b-col>
            <i class="ri-command-line ri-xl" />
            <span class="sidebar-menu-headings h8 pl-2">All Matches</span>
          </b-col>
        </b-row>
      </router-link>
      <b-row
        v-if="user.role === 'admin'"
        class="option-cols"
      >
        <b-col>
          <i class="ri-compasses-line ri-xl" />
          <span class="sidebar-menu-headings h8 pl-2">Representing...</span>
        </b-col>
      </b-row>
      <b-select
        v-if="user.role === 'admin'"
        v-model="selectedBusiness"
        :options="businessOptions"
      />
    </b-col>
  </b-sidebar>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import axios from '@/helpers/cleanAxios'
import LogoWithTextDark from '@/components/svgs/LogoWithTextDark'
import FeeCalculator from '@/components/FeeCalculator'

export default {
  name: 'Sidebar',
  components: {
    LogoWithTextDark,
    FeeCalculator,
  },
  data () {
    return {
      allBusinesses: [],
      businessOptions: [],
      selectedBusiness: {},
      notifications: {
        messages: [],
      },
      notificationsTimer: null,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters('auth', ['recruiter', 'business', 'agency']),
  },
  watch: {
    selectedBusiness (newVal) {
      const foundBusiness = this.allBusinesses.find(business => {
        if (business.id === newVal) {
          return true
        }
        return false
      })
      this.$store.commit('auth/SET_BUSINESS', foundBusiness)
      window.location.href = '/'
    },
  },
  mounted () {
    setTimeout(() => {
      if (window.sessionStorage.openCalculator) {
        this.$bvModal.show('FeeCalculator')
        window.sessionStorage.removeItem('openCalculator')
      }
    }, 50)

    // Start pinging for notifications every.length x seconds
    const fetchNotifications = () => {
      axios.get('/v2/notifications')
        .then(({ data }) => {
          this.notifications = data
        })
        .finally(() => {
          this.notificationsTimer = setTimeout(() => {
            fetchNotifications()
          }, 5 * 1000)
        })
    }
    fetchNotifications()
  },
  created () {
    if (this.user.role === 'admin') {
      axios.get('/admin/v2/businesses')
        .then(({ data }) => {
          this.allBusinesses = data
          this.businessOptions = data.map(business => {
            return {
              value: business.id,
              text: business.name,
            }
          }).sort((a, b) => a.text.localeCompare(b.text))
        })
        .catch((error) => {
          this.$bvToast.toast('Error fetching list of clients', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 3000,
            solid: true,
          })
          throw error
        })
    }
  },
  beforeDestroy () {
    clearTimeout(this.notificationsTimer)
  },
  methods: {
    logOut () {
      axios.post('/v2/logout').then(() => {
        window.localStorage.clear()
        window.sessionStorage.clear()
        window.location.href = '/'
      })
        .catch((error) => {
          this.$bvToast.toast('Error when trying to log out', {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 3000,
            solid: true,
          })
          throw error
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-cols {
  border-top: 2px solid red;
}
.sidebar-user {
  font-size: 16px;
  color: #39364f;
}
.user-pic {
  object-fit: cover;
  border-radius: 200px;
}
.sidebar_user-company {
  font-weight: 400;
  vertical-align: top;
  color: #39364f;
}
.sidebar-menu-headings {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  vertical-align: top;
}
.sidebar-logout {
  vertical-align: text-top;
}
.sidebar-nav-links {
  text-decoration: none;
  color: inherit;
  .small-btn {
    border: none !important;
    background: none !important;
    color: #ee8b7b;
    -webkit-box-shadow: none;
    box-shadow: none !important;
    &:hover,
    &:active,
    &.active {
      background: none !important;
      box-shadow: none !important;
      color: #ee8b7b;
    }
}
}
.option-cols {
  padding-left: 15px;

  &:hover,
  &:active,
  &.active {
    background-color: rgba(255, 255, 255, 0.4);
    border-left: 10px solid #ee8b7b;
    padding-left: 5px;
    border-radius: 3px;
  }
}
.badge {
  border-radius: 0;
  font-size: 12px;
  line-height: 1;
  padding: 0.375rem 0.5625rem;
  font-weight: normal;
  background-color: #323031;
  color: #fff;
  border-radius: 10rem;
}
a.badge-secondary:hover {
  background-color: #323031;
  color: #fff;
}
</style>
