import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import projects from './projects'
import matches from './matches'
import auth from '@/modules/auth/store'
import business from '@/modules/business/store'
import recruiter from '@/modules/recruiter/store'
import agency from '@/modules/agency/store'
import proposal from '@/components/proposal/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    business,
    matches,
    projects,
    recruiter,
    agency,
    proposal,
  },
  plugins: [createPersistedState()],
})
