<template>
  <svg
    width="124"
    height="57"
    viewBox="0 0 124 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.9549 30.0313H56.4701V34.7629H53.6777V21.8093H57.4203C58.4028 21.8093 59.185 21.8933 59.7667 22.0613C60.3614 22.2294 60.885 22.5203 61.3374 22.934C62.126 23.6321 62.5203 24.5887 62.5203 25.8039C62.5203 27.2906 62.0808 28.3636 61.2017 29.023C60.9044 29.2557 60.5553 29.4496 60.1545 29.6047L63.4705 34.123L61.3762 35.4028L57.5367 30.0119C57.4203 30.0249 57.2264 30.0313 56.9549 30.0313ZM59.1462 27.5686C59.5987 27.2583 59.8249 26.7412 59.8249 26.0172C59.8249 25.2804 59.6374 24.7374 59.2625 24.3883C58.9006 24.0393 58.1378 23.8648 56.9743 23.8648H56.4701V27.9758H56.6834C57.9633 27.9758 58.7842 27.8401 59.1462 27.5686Z"
      fill="#19242C"
    />
    <path
      d="M66.6194 30.0701C66.6452 32.0093 67.4985 32.9789 69.1791 32.9789C69.9677 32.9789 70.7498 32.8302 71.5255 32.5328L71.9909 34.3751C70.9954 34.8017 69.8901 35.015 68.6749 35.015C65.611 35.015 64.0791 33.2116 64.0791 29.6047C64.0791 28.1826 64.4605 26.9998 65.2232 26.056C65.9859 25.1123 67.0848 24.6404 68.5198 24.6404C69.6703 24.6404 70.5559 24.9507 71.1764 25.5712C71.8099 26.1788 72.1266 27.0321 72.1266 28.1309C72.1266 28.7773 72.0426 29.4237 71.8745 30.0701H66.6194ZM69.9548 28.5963V28.48C69.9548 27.2001 69.5023 26.5602 68.5973 26.5602C67.5114 26.5602 66.8586 27.2389 66.6388 28.5963H69.9548Z"
      fill="#19242C"
    />
    <path
      d="M78.8258 34.5884C78.1665 34.8728 77.5266 35.015 76.9061 35.015C76.2855 35.015 75.7749 34.9439 75.3741 34.8017C74.9734 34.6595 74.6696 34.4979 74.4627 34.3169C74.2688 34.1359 74.1201 33.8709 74.0167 33.5218C73.9133 33.1728 73.8486 32.8819 73.8228 32.6492C73.8099 32.4036 73.8034 32.0674 73.8034 31.6408V21.1887H76.5376V31.796C76.5376 32.1709 76.5893 32.4359 76.6928 32.591C76.8091 32.7462 77.0353 32.8237 77.3715 32.8237C77.7076 32.8237 78.0566 32.7785 78.4186 32.688L78.8258 34.5884Z"
      fill="#19242C"
    />
    <path
      d="M88.7465 34.4332C88.0872 34.7952 87.3632 34.9762 86.5746 34.9762C85.799 34.9762 85.2237 34.6789 84.8488 34.0842C84.099 34.7047 83.2199 35.015 82.2115 35.015C81.2031 35.015 80.4404 34.7629 79.9233 34.2587C79.4062 33.7416 79.1476 33.0435 79.1476 32.1644C79.1476 31.5827 79.2704 31.0849 79.5161 30.6712C79.7617 30.2576 80.0784 29.9538 80.4662 29.7598C81.1773 29.4237 81.9142 29.2557 82.6769 29.2557C83.4526 29.2557 84.0731 29.275 84.5385 29.3138V28.3636C84.5385 27.6914 84.4028 27.2583 84.1313 27.0644C83.8598 26.8705 83.3944 26.7735 82.7351 26.7735C82.0887 26.7735 81.3583 26.9093 80.5438 27.1807L79.9815 25.4743C80.4598 25.1899 81.0415 24.9766 81.7267 24.8344C82.4248 24.6792 83.0195 24.6017 83.5107 24.6017C84.8294 24.6017 85.7666 24.8861 86.3225 25.4549C86.8913 26.0108 87.1758 26.9674 87.1758 28.3249V32.3971C87.1758 32.7591 87.3374 32.9401 87.6606 32.9401C87.8286 32.9401 88.0678 32.9013 88.378 32.8237L88.7465 34.4332ZM82.9484 33.2116C83.4913 33.2116 84.0214 33.0435 84.5385 32.7074V30.6712C84.1765 30.6454 83.8598 30.6325 83.5883 30.6325C82.3085 30.6325 81.6685 31.0461 81.6685 31.8735C81.6685 32.7655 82.0951 33.2116 82.9484 33.2116Z"
      fill="#19242C"
    />
    <path
      d="M95.3351 24.6404C96.4598 24.6404 97.2226 24.9313 97.6233 25.5131C98.037 26.0819 98.2439 26.8963 98.2439 27.9564V34.7629H95.529V28.4412C95.529 27.937 95.445 27.5492 95.2769 27.2777C95.1218 26.9933 94.734 26.8511 94.1134 26.8511C93.4929 26.8511 92.9305 27.0902 92.4264 27.5686V34.7629H89.6533V24.815H91.9416L92.1743 25.9203C92.5492 25.5712 93.021 25.2739 93.5899 25.0283C94.1716 24.7697 94.7534 24.6404 95.3351 24.6404Z"
      fill="#19242C"
    />
    <path
      d="M104.467 35.015C103.135 35.015 102.03 34.5884 101.151 33.7351C100.272 32.869 99.832 31.5697 99.832 29.8374C99.832 28.0922 100.265 26.7929 101.131 25.9397C101.997 25.0735 103.109 24.6404 104.467 24.6404C105.268 24.6404 106.18 24.7891 107.201 25.0864L106.677 27.045C105.889 26.864 105.255 26.7735 104.777 26.7735C104.014 26.7735 103.426 27.0062 103.012 27.4716C102.611 27.9241 102.411 28.7321 102.411 29.8956C102.411 31.0591 102.631 31.8671 103.07 32.3195C103.523 32.772 104.15 32.9982 104.951 32.9982C105.365 32.9982 105.999 32.9336 106.852 32.8043L107.181 34.569C106.16 34.8663 105.255 35.015 104.467 35.015Z"
      fill="#19242C"
    />
    <path
      d="M110.61 30.0701C110.636 32.0093 111.49 32.9789 113.17 32.9789C113.959 32.9789 114.741 32.8302 115.517 32.5328L115.982 34.3751C114.986 34.8017 113.881 35.015 112.666 35.015C109.602 35.015 108.07 33.2116 108.07 29.6047C108.07 28.1826 108.451 26.9998 109.214 26.056C109.977 25.1123 111.076 24.6404 112.511 24.6404C113.661 24.6404 114.547 24.9507 115.167 25.5712C115.801 26.1788 116.118 27.0321 116.118 28.1309C116.118 28.7773 116.034 29.4237 115.866 30.0701H110.61ZM113.946 28.5963V28.48C113.946 27.2001 113.493 26.5602 112.588 26.5602C111.502 26.5602 110.85 27.2389 110.63 28.5963H113.946Z"
      fill="#19242C"
    />
    <path
      d="M122.72 26.8123C121.66 26.8123 120.916 27.1161 120.49 27.7237V34.7629H117.775V24.815H120.024L120.238 26.0754C120.988 25.0929 121.944 24.6017 123.108 24.6017C123.431 24.6017 123.728 24.634 124 24.6986L123.554 26.9287C123.269 26.8511 122.991 26.8123 122.72 26.8123Z"
      fill="#19242C"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="28"
      y="14"
      width="15"
      height="29"
    >
      <rect
        x="28.252"
        y="14.1259"
        width="14.1258"
        height="28.2516"
        fill="#D65C4B"
      />
    </mask>
    <g mask="url(#mask0)">
      <circle
        cx="28.2528"
        cy="28.2521"
        r="14.1258"
        fill="#EE8B7B"
      />
    </g>
    <mask
      id="mask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="14"
      y="14"
      width="29"
      height="29"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.2528 42.3775C36.0542 42.3775 42.3786 36.0531 42.3786 28.2517C42.3786 20.4502 36.0542 14.1259 28.2528 14.1259C20.4513 14.1259 14.127 20.4502 14.127 28.2517C14.127 36.0531 20.4513 42.3775 28.2528 42.3775ZM28.2535 35.3146C32.1542 35.3146 35.3164 32.1525 35.3164 28.2517C35.3164 24.351 32.1542 21.1888 28.2535 21.1888C24.3527 21.1888 21.1906 24.351 21.1906 28.2517C21.1906 32.1525 24.3527 35.3146 28.2535 35.3146Z"
        fill="#F1CAB9"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        d="M14.127 9.88806H28.2528V46.6151H14.127V9.88806Z"
        fill="#F1CAB9"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoWithTextDark',
}
</script>

<style>

</style>
