// Label is used for our own components and backend
// Order is used for ordering
// Value is used for comparisons
// Text is used for bootstrap-vue components
// It would be nice to get rid of "text" one day :)
const BUSINESS_STATUSES = {
  mapping: {
    label: 'Mapping',
    order: 0,
    value: 'Mapping',
    text: 'Mapping',
  },
  publishedAProject: {
    label: 'Published a project',
    order: 1,
    value: 'Published a project',
    text: 'Published a project',
  },
  activeContracts: {
    label: 'Active contract(s)',
    order: 2,
    value: 'Active contract(s)',
    text: 'Active contract(s)',
  },
  farming: {
    label: 'Farming',
    order: 3,
    value: 'Farming',
    text: 'Farming',
  },
  didntPublishAProject: {
    label: 'Didn\'t publish a project',
    order: 4,
    value: 'Didn\'t publish a project',
    text: 'Didn\'t publish a project',
  },
  didntSignAContract: {
    label: 'Didn\'t sign a contract',
    order: 5,
    value: 'Didn\'t sign a contract',
    text: 'Didn\'t sign a contract',
  },
  bannedFromPlatform: {
    label: 'Banned from platform',
    order: 6,
    value: 'Banned from platform',
    text: 'Banned from platform',
  },
}

const RECRUITER_STATUSES = {
  emailVerification: {
    label: 'Email verification',
    order: 0,
    value: 'Email verification',
    text: 'Email verification',
  },
  finishProfile: {
    label: 'Finish profile',
    order: 1,
    value: 'Finish profile',
    text: 'Finish profile',
  },
  profileReview: {
    label: 'Profile review',
    order: 2,
    value: 'Profile review',
    text: 'Profile review',
  },
  vetting: {
    label: 'Vetting',
    order: 3,
    value: 'Vetting',
    text: 'Vetting',
  },
  active: {
    label: 'Active',
    order: 4,
    value: 'Active',
    text: 'Active',
  },
  passive: {
    label: 'Passive',
    order: 5,
    value: 'Passive',
    text: 'Passive',
  },
  needsQualityValidation: {
    label: 'Needs quality validation',
    order: 6,
    value: 'Needs quality validation',
    text: 'Needs quality validation',
  },
  maybeLater: {
    label: 'Maybe later',
    order: 7,
    value: 'Maybe later',
    text: 'Maybe later',
  },
  inHouseOpportunities: {
    label: 'In-house opportunities',
    order: 8,
    value: 'In-house opportunities',
    text: 'In-house opportunities',
  },
  badExperience: {
    label: 'Bad experience',
    order: 9,
    value: 'Bad experience',
    text: 'Bad experience',
  },
  outOfScope: {
    label: 'Out of scope',
    order: 10,
    value: 'Out of scope',
    text: 'Out of scope',
  },
}

const AGENCY_STATUSES = RECRUITER_STATUSES

const MATCH_STATUSES = {
  new: {
    label: 'New',
    order: 0,
    value: 'New',
    text: 'New',
  },
  recruiterIsInterested: {
    label: 'Recruiter is interested',
    order: 1,
    value: 'Recruiter is interested',
    text: 'Recruiter is interested',
  },
  clientIsInterested: {
    label: 'Client is interested',
    order: 2,
    value: 'Client is interested',
    text: 'Client is interested',
  },
  introMeeting: {
    label: 'Intro meeting',
    order: 3,
    value: 'Intro meeting',
    text: 'Intro meeting',
  },
  proposal: {
    label: 'Proposal',
    order: 4,
    value: 'Proposal',
    text: 'Proposal',
  },
  contractSigning: {
    label: 'Contract signing',
    order: 6,
    value: 'Contract signing',
    text: 'Contract signing',
  },
  active: {
    label: 'Active',
    order: 7,
    value: 'Active',
    text: 'Active',
  },
  paused: {
    label: 'Paused',
    order: 8,
    value: 'Paused',
    text: 'Paused',
  },
  feedback: {
    label: 'Feedback',
    order: 9,
    value: 'Feedback',
    text: 'Feedback',
  },
  contractCompleted: {
    label: 'Contract completed',
    order: 10,
    value: 'Contract completed',
    text: 'Contract completed',
  },
  contractCanceled: {
    label: 'Contract canceled',
    order: 11,
    value: 'Contract canceled',
    text: 'Contract canceled',
  },
  notHired: {
    label: 'Not hired',
    order: 12,
    value: 'Not hired',
    text: 'Not hired',
  },
  recruiterDeclined: {
    label: 'Recruiter declined',
    order: 13,
    value: 'Recruiter declined',
    text: 'Recruiter declined',
  },
  clientDeclined: {
    label: 'Client declined',
    order: 14,
    value: 'Client declined',
    text: 'Client declined',
  },
  matchExpired: {
    label: 'Match expired',
    order: 16,
    value: 'Match expired',
    text: 'Match expired',
  },
}

const PROJECT_STATUSES = {
  draft: {
    label: 'Draft',
    order: 0,
    value: 'Draft',
    text: 'Draft',
  },
  open: {
    label: 'Open',
    order: 1,
    value: 'Open',
    text: 'Open',
  },
  active: {
    label: 'Active',
    order: 2,
    value: 'Active',
    text: 'Active',
  },
  paused: {
    label: 'Paused',
    order: 3,
    value: 'Paused',
    text: 'Paused',
  },
  closed: {
    label: 'Closed',
    order: 4,
    value: 'Closed',
    text: 'Closed',
  },
}

const CLOSING_REASONS = {
  projectNotPublished: {
    label: 'Project not published',
    order: 0,
    value: 'Project not published',
    text: 'Project not published',
  },
  contractNotSigned: {
    label: 'Contract not signed',
    order: 1,
    value: 'Contract not signed',
    text: 'Contract not signed',
  },
  contractsCompleted: {
    label: 'Contract(s) completed',
    order: 2,
    value: 'Contract(s) completed',
    text: 'Contract(s) completed',
  },
  contractsCanceled: {
    label: 'Contract(s) canceled',
    order: 3,
    value: 'Contract(s) canceled',
    text: 'Contract(s) canceled',
  },
}

/**
 * Convert statuses to array of statuses
 *
 * @param {object} statuses Statuses to remap
 * @returns Array of statuses
 */
function statusToArray (statuses) {
  return Object.keys(statuses).map(k => statuses[k])
}

/**
 * Searches status object from alls statuses by it's value
 *
 * @param {object} statuses Statuses to search from
 * @param {string} status Status value to search for
 * @returns Status object
 */
function statusByValue (statuses, status) {
  const keys = Object.keys(statuses)
  for (let i = 0; i < keys.length; i++) {
    const element = statuses[keys[i]]
    if (element.value === status) {
      return element
    }
  }
  return null
}

export {
  BUSINESS_STATUSES,
  RECRUITER_STATUSES,
  AGENCY_STATUSES,
  MATCH_STATUSES,
  PROJECT_STATUSES,
  CLOSING_REASONS,
  statusToArray,
  statusByValue,
}
