<template>
  <div
    id="app"
    :class="{'sidebar-margin': $store.state.auth.user}"
  >
    <!-- Show different headers for different roles -->
    <!-- having multiple components makes code a lot cleaner -->
    <!-- than having one huge component full of "if" statements -->
    <RecruiterHeader v-if="($store.state.auth || {}).selectedRecruiter" />
    <BusinessHeader v-if="($store.state.auth || {}).selectedBusiness" />
    <AgencyHeader v-if="($store.state.auth || {}).selectedAgency" />
    <Sidebar v-if="$store.state.auth.user" />
    <router-view />
  </div>
</template>

<script>
import RecruiterHeader from '@/modules/recruiter/components/Header'
import BusinessHeader from '@/modules/business/components/Header'
import AgencyHeader from '@/modules/agency/components/Header'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'App',
  components: {
    RecruiterHeader,
    BusinessHeader,
    AgencyHeader,
    Sidebar,
  },
}
</script>

<style lang="scss">
.sidebar-margin {
  margin-left: 256px;
}
</style>
