import axios from '@/helpers/cleanAxios'

export default {
  namespaced: true,

  state: () => ({
    selectedMatch: null,

    signupForm: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      goals: [],
      linkedin: '',
      country: '',
      services: [],
      industries: [],
      regions: [],
      local_insights: [],
      roles: [],
      availability: '',
      previous_clients: '',
      seniority: '',
      fee_ranges: [],
      pricing_models: [],
      english_level: '',
      available_from: '',
      type: '',
      title: '',
      description: '',
      profile_picture: '/images/file_upload.svg',
      native_languages: [],
      languages: [],
      other_goal: '',

      // Helpers
      otherActive: false,
    },
  }),

  mutations: {
    SET_MATCH (state, match) {
      state.selectedMatch = match
    },
    UPDATE_STORE (state, payload) {
      if (payload.nestedObject) {
        state[payload.nestedObject][payload.data.field] = payload.data.value
      } else {
        state[payload.data.field] = payload.data.value
      }
    },
  },

  actions: {
    submitSignup ({ state }) {
      return axios.post('/public/v2/signup/recruiter',
        remapForm(state.signupForm),
      )
        .then(({ data }) => data)
    },
    updateSignup ({ state }, token) {
      return axios.post('/public/v2/signup/recruiter/' + token,
        remapForm(state.signupForm),
      )
        .then(({ data }) => data)
    },
  },

  getters: {
    selectedMatch: (state) => {
      return state.selectedMatch
    },
  },
}

function remapForm (form) {
  const sendData = { ...form }
  if (form.availability === 'available_from') {
    sendData.availability = form.available_from
  }
  if (form.profile_picture === '/images/file_upload.svg') {
    sendData.profile_picture = 'https://files.relancer.com/avatar_golden.svg'
  }
  return sendData
}
