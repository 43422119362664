import Axios from '@/helpers/cleanAxios'
import router from '@/router'
import Vue from 'vue'

export default {
  namespaced: true,

  state: () => ({
    user: null,
    selectedBusiness: null,
    selectedRecruiter: null,
    selectedAgency: null,
  }),

  mutations: {
    SET_USER (state, payload) {
      state.user = payload
    },
    SET_BUSINESS (state, payload) {
      Vue.set(state, 'selectedBusiness', payload)
      state.selectedRecruiter = null
      state.selectedAgency = null
    },
    SET_RECRUITER (state, payload) {
      Vue.set(state, 'selectedRecruiter', payload)
      state.selectedBusiness = null
      state.selectedAgency = null
    },
    SET_AGENCY (state, payload) {
      Vue.set(state, 'selectedAgency', payload)
      state.selectedBusiness = null
      state.selectedRecruiter = null
    },
  },

  actions: {
    login ({ commit }, payload) {
      return Axios.post('/public/v2/login', payload)
        .then(({ data }) => {
          // Check if dealing with business or recruiter
          // Read route from sessionStorage, if no route then go to /projects
          if (data.businesses.length) {
            commit('SET_BUSINESS', data.businesses[0])
            commit('SET_USER', data)
            if (window.sessionStorage.redirectTo) {
              router.push(window.sessionStorage.redirectTo)
            } else {
              router.push('/business/' + data.businesses[0].id + '/projects')
            }
          } else if (data.recruiter) {
            commit('SET_RECRUITER', data.recruiter)
            commit('SET_USER', data)
            if (window.sessionStorage.redirectTo) {
              router.push(window.sessionStorage.redirectTo)
            } else {
              router.push('/recruiter/' + data.recruiter.id + '/matches')
            }
          } else if (Array.isArray(data.agencies) && data.agencies.length > 0) {
            commit('SET_AGENCY', data.agencies[0])
            commit('SET_USER', data)
            if (window.sessionStorage.redirectTo) {
              router.push(window.sessionStorage.redirectTo)
            } else {
              router.push('/agency/' + data.agencies[0].id + '/matches')
            }
          }
          return data
        })
    },
    forgotPassword (ctx, email) {
      return Axios.post('/public/v2/password/forgot', {
        email,
      }).then(({ data }) => data)
    },

    /**
     * Fetch user himself and related agency, business or recruiter profile
     */
    self ({ commit, dispatch, state }) {
      return Axios.get('/v2/self')
        .then(({ data }) => {
          commit('SET_USER', data)

          // Fetch related agency, recruiter or business
          if (state.selectedBusiness) {
            dispatch('updateBusiness', state.selectedBusiness.id)
          } else if (state.selectedAgency) {
            dispatch('updateAgency', state.selectedAgency.id)
          } else if (state.selectedRecruiter) {
            dispatch('updateRecruiter', state.selectedRecruiter.id)
          }
          return data
        })
    },

    /**
     * Fetches and updates selectedAgency
     */
    updateAgency ({ commit }, id) {
      return Axios.get(`/v2/agencies/${id}`)
        .then(({ data }) => {
          commit('SET_AGENCY', data)
          return data
        })
        .catch((err) => {
          console.error(`Loading agency ${id} failed`)
          throw err
        })
    },

    /**
     * Fetches and updates selectedBusiness
     */
    updateBusiness ({ commit }, id) {
      return Axios.get(`/v2/businesses/${id}`)
        .then(({ data }) => {
          commit('SET_BUSINESS', data)
          return data
        })
        .catch((err) => {
          console.error(`Loading business ${id} failed`)
          throw err
        })
    },

    /**
     * Fetches and updates selectedRecruiter
     */
    updateRecruiter ({ commit }, id) {
      return Axios.get(`/v2/recruiters/${id}`)
        .then(({ data }) => {
          commit('SET_RECRUITER', data)
          return data
        })
        .catch((err) => {
          console.log(`Loading recruiter ${id} failed`)
          throw err
        })
    },
  },

  getters: {
    business: (state) => state.selectedBusiness,
    recruiter: (state) => state.selectedRecruiter,
    agency: (state) => state.selectedAgency,
  },
}
