<template>
  <b-row class="pt-3 pb-3 m-0 header">
    <!-- RouteName BusinessProfile Buttons -->
    <b-col v-if="$route.name === 'BusinessProfile'">
      <b-col class="d-flex justify-content-end">
        <b-btn
          variant="ghost"
          :to="`/business/${business.id}/edit`"
        >
          <i class="ri-edit-2-line pr-2" />
          <span>
            Edit profile
          </span>
        </b-btn>
      </b-col>
    </b-col>
    <!-- RouteName ProposalView Buttons -->
    <b-col v-if="$route.name === 'ProposalView' && selectedProject">
      <b-col class="h7">
        {{ selectedMatch.project.title }}
      </b-col>
    </b-col>
    <!-- RouteName ProjectSingle Buttons -->
    <b-col
      v-if="$route.name === 'ProjectSingle' && selectedProject"
      class="h7 d-flex"
    >
      <b-col>
        {{ selectedProject.title }}
      </b-col>
      <b-col
        v-if="selectedProject.status === PROJECT_STATUSES.draft.value"
        class="d-flex justify-content-end"
      >
        <b-btn
          variant="ghost"
          :to="`/project/${selectedProject.id}/edit`"
        >
          <i class="ri-edit-2-line pr-2" />
          <span>
            Edit draft
          </span>
        </b-btn>
        <b-btn
          class="ml-3"
          variant="primary"
          @click.prevent="publish()"
        >
          <i class="ri-send-plane-line pr-2" />
          <span>
            Publish
          </span>
        </b-btn>
      </b-col>
      <b-col
        v-if="![
          PROJECT_STATUSES.draft.value,
          PROJECT_STATUSES.closed.value,
        ].includes(selectedProject.status)"
        class="d-flex justify-content-end"
      >
        <b-btn
          class="mr-3"
          variant="ghost"
          :to="`/business/${business.id}/matches?project=${selectedProject.id}`"
        >
          <i class="ri-eye-line ri-sm pr-2" />
          <span>
            View matches
          </span>
        </b-btn>
      </b-col>
    </b-col>
    <!-- RouteName BusinessMatch Buttons -->
    <b-col v-if="$route.name === 'BusinessMatch' && selectedMatch">
      <b-col class="d-flex justify-content-end">
        <b-btn
          v-if="![
            MATCH_STATUSES.clientDeclined.value,
            MATCH_STATUSES.active.value,
          ].includes(selectedMatch.status)"
          v-b-modal.decline-modal
          class="mr-3"
          variant="primary"
        >
          <i class="ri-thumb-down-line pr-2" />
          <span>
            Decline
          </span>
        </b-btn>
        <b-btn
          v-if="![
            MATCH_STATUSES.clientIsInterested.value,
            MATCH_STATUSES.active.value,
          ].includes(selectedMatch.status)"
          v-b-modal.accept-modal
          class="mr-3"
          variant="secondary"
        >
          <i class="ri-thumb-up-line pr-2" />
          <span>
            Accept
          </span>
        </b-btn>
        <!-- <b-btn class="btn-ghost mr-3">
          <i class="ri-heart-line ri-sm pr-2" />
          <small class="btn-txt">
            <b>Save for later</b>
          </small>
        </b-btn> -->
      </b-col>
    </b-col>
    <!-- Modals -->
    <b-modal
      v-if="selectedMatch"
      id="accept-modal"
      ok-only
      ok-title="Accept"
      no-stacking
      centered
      @ok="modalConfirmation"
    >
      <label class="mt-3 pl-3 pr-3">
        When you accept, 3-way chat between you, recruiter or agency and advisor will be created.
      </label>
    </b-modal>
    <b-modal
      id="decline-modal"
      ok-only
      ok-title="Send"
      no-stacking
      centered
      @ok="modalDeclineConfirmation"
    >
      <label class="h7 mb-3">
        Not the best match?
      </label>
      <label class="mb-3 pl-3 pr-3">
        Your feedback helps improve your future matches.
      </label>
      <b-form-textarea
        v-model="decline_comment"
        class="modal-feedback"
        placeholder="Add your feedback here..."
        rows="4"
      />
    </b-modal>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/helpers/cleanAxios'
import { PROJECT_STATUSES, MATCH_STATUSES } from '@/helpers/statuses'

export default {
  name: 'Header',
  data () {
    return {
      decline_comment: '',
      question: '',
      PROJECT_STATUSES,
      MATCH_STATUSES,
    }
  },
  computed: {
    ...mapGetters('auth', ['business']),
    ...mapGetters('business', [
      'selectedProject',
      'selectedMatch',
    ]),
  },
  methods: {
    modalConfirmation () {
      axios.patch(`/v2/matches/${this.$route.params.id}/accept`, {
      })
        .then(() => {
          location.reload()
        })
        .catch(() => {
          this.$bvModal.msgBoxOk('Error accepting a match!', {
            bodyBgVariant: 'danger',
          })
        })
    },
    modalDeclineConfirmation () {
      axios.patch(`/v2/matches/${this.$route.params.id}/decline`, {
        decline_comment: this.decline_comment,
      })
        .then(() => {
          this.decline_comment = ''
          this.$bvModal.msgBoxOk('Thanks for your message!', {
            title: ' ',
            size: 'md',
            buttonSize: 'lg',
            headerClass: 'p-4',
            centered: true,
          })
            .then(() => {
              location.reload()
            })
        })
        .catch(() => {
          this.$bvModal.msgBoxOk('Error saving response!', {
            bodyBgVariant: 'danger',
          })
        })
    },
    publish () {
      axios.patch(`/v2/projects/${this.selectedProject.id}/publish`)
        .then(() => {
          // Don't have publish confirmation illustration yet
          this.$router.push('/published/project')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  background-color: #f8eeec;
  min-height: 73px;
}
</style>
