export default [
  {
    path: '/admin/user',
    name: 'UserList',
    component: () => import('@/modules/admin/views/UserList.vue'),
  },
  {
    path: '/admin/user/create',
    name: 'UserCreate',
    component: () => import('@/modules/admin/views/UserForm.vue'),
  },
  {
    path: '/admin/user/:id/edit',
    name: 'UserEdit',
    component: () => import('@/modules/admin/views/UserForm.vue'),
  },
  {
    path: '/admin/business',
    name: 'BusinessList',
    component: () => import('@/modules/admin/views/BusinessList.vue'),
  },
  {
    path: '/admin/matches',
    name: 'MatchesList',
    component: () => import('@/modules/admin/views/MatchesList.vue'),
  },
  {
    path: '/admin/business/create',
    name: 'BusinessCreate',
    component: () => import('@/modules/admin/views/BusinessForm.vue'),
  },
  {
    path: '/admin/project/create',
    name: 'ProjectCreate',
    component: () => import('@/views/project/ProjectForm.vue'),
  },
  {
    path: '/admin/project/:id/edit',
    name: 'ProjectEdit',
    component: () => import('@/views/project/ProjectForm.vue'),
  },
  {
    path: '/admin/projects',
    name: 'ProjectList',
    component: () => import('@/views/project/ProjectsList.vue'),
  },

  // Admin business routes
  {
    path: '/admin/business/:id/edit',
    name: 'AdminBusinessEdit',
    component: () => import('@/modules/admin/views/BusinessForm.vue'),
  },

  // Admin recruiter routes
  {
    path: '/admin/recruiter',
    name: 'RecruiterList',
    component: () => import('@/modules/recruiter/views/RecruiterList.vue'),
  },
  {
    path: '/admin/recruiter/:id/edit',
    name: 'AdminRecruiterEdit',
    component: () => import('@/modules/recruiter/views//RecruiterForm.vue'),
  },
  {
    path: '/admin/project/:id/edit',
    name: 'AdminProjectEdit',
    component: () => import('@/views/project/ProjectForm.vue'),
  },
  // Admin agency routes
  {
    path: '/admin/agency',
    name: 'AdminAgenciesList',
    component: () => import('@/modules/agency/views/AdminAgenciesList.vue'),
  },
  {
    path: '/admin/agency/:id/edit',
    name: 'AdminAgencyEdit',
    component: () => import('@/modules/agency/views/AgencyEdit.vue'),
  },
  // nativeLangugage is no for Recruiter
]
